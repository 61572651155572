import React,{ useState }  from "react"
import {
  hubbleGeneralQuestions,
  subscriptionQuestions,
  shippingQuestions,
  paymentQuestions,
  hubbleContactLensesQuestions,
} from "./faqs"
import "../FaqSection/style.scss"
import QuestionAnswer from "../QuestionAnswer";
import WebpImage from "../WebpImage";

const CanadaFaqSection = () => {
  const [activeCategory, setActiveCategory] = useState('hubbleGeneral')

  const handleCategoryClick = (category, isMobile) => {
    if (activeCategory === category && isMobile) {
      setActiveCategory('')
    } else {
      setActiveCategory(category)
    }
  }

  const arrow = (category) => {
    return (
      <span className='caret-wrapper'>
        {activeCategory === category ?
          <WebpImage fileName='assets/CaretImg/down-caret.svg' />
        :
          <WebpImage fileName="assets/CaretImg/up-caret.svg" />
        }
      </span>
    )
  }

  return (
    <>
      <div className="faq-section mobile-only">
        <ul className="mobile-faq-list">
          <h5 className="nav-header" data-color={"blue"}>For Contacts</h5>
          <li className={`faq-category ${activeCategory === 'hubbleGeneral' ? 'active' : ''}`}  onClick={() => handleCategoryClick('hubbleGeneral', true)}>
            <a href="#use-of-service">
              General
            </a>
            {arrow('hubbleGeneral')}
          </li>
          <div className={`question-section ${activeCategory === 'hubbleGeneral' ? 'active' : ''}`}>
            {hubbleGeneralQuestions.map( (question) => {
              return <QuestionAnswer faq={question} />
            })}
          </div>

          <li className={`faq-category ${activeCategory === 'subscription' ? 'active' : ''}`}  onClick={() => handleCategoryClick('subscription', true)}>
            <a href="#use-of-service">
              Subscription
            </a>
            {arrow('subscription')}
          </li>
          <div className={`question-section ${activeCategory === 'subscription' ? 'active' : ''}`}>
            {subscriptionQuestions.map( (question) => {
              return <QuestionAnswer faq={question}/>
            })}
          </div>

          <li className={`faq-category ${activeCategory === 'shipping' ? 'active' : ''}`}  onClick={() => handleCategoryClick('shipping', true)}>
            <a href="#lens-subscription">
              Shipping
            </a>
            {arrow('shipping')}
          </li>
          <div className={`question-section ${activeCategory === 'shipping' ? 'active' : ''}`}>
            {shippingQuestions.map( (question) => {
              return <QuestionAnswer faq={question}/>
            })}
          </div>

          <li className={`faq-category ${activeCategory === 'payment' ? 'active' : ''}`}  onClick={() => handleCategoryClick('payment', true)}>
            <a href="#other-products">
              Payment
            </a>
            {arrow('payment')}
          </li>
          <div className={`question-section ${activeCategory === 'payment' ? 'active' : ''}`}>
            {paymentQuestions.map( (question) => {
              return <QuestionAnswer faq={question}/>
            })}
          </div>

          <li className={`faq-category ${activeCategory === 'hubbleContacts' ? 'active' : ''}`}  onClick={() => handleCategoryClick('hubbleContacts', true)}>
            <a href="#conditions-of-use">
              Hubble Contact Lenses
            </a>
            {arrow('hubbleContacts')}
          </li>
          <div className={`question-section ${activeCategory === 'hubbleContacts' ? 'active' : ''}`}>
            {hubbleContactLensesQuestions.map( (question) => {
              return <QuestionAnswer faq={question}/>
            })}
          </div>
        </ul>
      </div>

      <div className="faq-section  section desktop-only grid-section">
        <div className='row'>
          <div className='col-md-4 desktop-only'>
            <div className="table-of-contents">
              <ul className="side-nav">
                <h5 className="nav-header" data-color={"blue"}>For Contacts</h5>
                <li className={`faq-category ${activeCategory === 'hubbleGeneral' ? 'active' : ''}`}  onClick={() => handleCategoryClick('hubbleGeneral', true)}>
                  <a href="#hubbleGeneral">
                    General
                  </a>
                </li>
                <li className={`faq-category ${activeCategory === 'subscription' ? 'active' : ''}`}  onClick={() => handleCategoryClick('subscription', false)}>
                  <a href="#use-of-service">
                    Subscription
                  </a>
                </li>

                <li className={`faq-category ${activeCategory === 'shipping' ? 'active' : ''}`}  onClick={() => handleCategoryClick('shipping', false)}>
                  <a href="#lens-subscription">
                    Shipping
                  </a>
                </li>

                <li className={`faq-category ${activeCategory === 'payment' ? 'active' : ''}`}  onClick={() => handleCategoryClick('payment', false)}>
                  <a href="#other-products">
                    Payment
                  </a>
                </li>

                <li className={`faq-category ${activeCategory === 'hubbleContacts' ? 'active' : ''}`}  onClick={() => handleCategoryClick('hubbleContacts', false)}>
                  <a href="#frames">
                    Hubble Contact Lenses
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-8 desktop-only'>
            <div className='question-section'>
              {activeCategory === 'hubbleGeneral' &&
                hubbleGeneralQuestions.map( (question) => {
                  return <QuestionAnswer faq={question}/>
                })
              }
              {activeCategory === 'subscription' &&
                subscriptionQuestions.map( (question) => {
                  return <QuestionAnswer faq={question}/>
                })
              }
              {activeCategory === 'shipping' &&
                shippingQuestions.map( (question) => {
                  return <QuestionAnswer faq={question}/>
                })
              }
              {activeCategory === 'payment' &&
                paymentQuestions.map( (question) => {
                  return <QuestionAnswer faq={question}/>
                })
              }
              {activeCategory === 'hubbleContacts' &&
                hubbleContactLensesQuestions.map( (question) => {
                  return <QuestionAnswer faq={question}/>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CanadaFaqSection
