export const hubbleGeneralQuestions = [
  {
    question: `How do I log in to the Hubble Member Portal?`,
    answer: `You can access our customer member portal <a href="https://ca.hubblecontacts.com/account/login">HERE</a>. Use your Hubble login credentials to view your orders, adjust your shipment frequency, change your shipment address and more. You can also activate your account or reset your password via the member portal login page.`
  },
  {
    question: `I still need assistance, how can I speak with someone about my order?`,
    answer: `Our customer service team is ready to help you with anything regarding your order with Hubble, via phone or email. Feel free to give us a call at <a href="tel:1-844-334-1640">1-844-334-1640</a>, 7 days a week, from 9am - 9pm Eastern Time or shoot us an email at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a>.`
  },
]

export const subscriptionQuestions = [
  {
    question: `How does my subscription work?`,
    answer: `Our subscription makes it super easy for you to get contact lenses delivered right to your door—right when you need them.
    <br>
    <br>
    Once you place your order, we’ll pack, prep and ship your first order of contacts. After that, your recurring subscription orders will be sent every 3 months for Hubble Classic, Hydro by Hubble, and SkyHy by Hubble Daily, and every 6 months for SkyHy by Hubble Monthly.
    <br>
    <br>
    We charge your card on file each time your lenses are shipped, and you can cancel your subscription any time— right up until we ship your lenses and charge your card for the period.
  `,
  },
  {
    question: `Can I change the frequency of my shipments?`,
    answer: `Yes! Our member portal allows you to easily manage your order frequency. You also have the option to skip an upcoming order if needed.`,
  },
  {
    question: `Will my subscription just stop when my prescription expires?`,
    answer: `<span>We hope not! You can always provide us with a copy of your updated prescription or reach out to us at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a>.</span>
    `,
  },
  {
    question: `What is your return policy?`,
    answer: `Love it or we’ll cover it—that’s us looking out for you! You can return any unopened boxes of contact lenses, for any reason, within 14 days of receipt to receive a full refund, excluding shipping and handling costs.
    <br>
    <br>
    Additionally, you may exchange your contact lenses at no cost within 14 days of receipt if you have opened the box and found that they are different from what you ordered or are defective. Shipping for this is on us!
    <br>
    <br>
    Your return must include all of the contact lens boxes that you received in the shipment. For example, if you signed up to receive three months of contact lenses per order, then your return must include all three months' worth of contact lenses.
    <br>
    <br>
    <span>The fastest way to initiate your return or exchange is to contact us via chat or phone at <a href="tel:+1-844-334-1640">+1-844-334-1640</a>. You can also email us at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a>.</span>`,
  },
  {
    question: `After my first order, when will my regular refill orders ship?`,
    answer: `
    After your Hubble Classic $1 starter pack order is placed, your regular 3-month supply will ship at least 10 days after the first order was placed.
    <br>
    <br>
    If you’ve ordered Hydro by Hubble Daily or SkyHy by Hubble Daily, your regular refill order will ship 28 days after your first order was placed.
    <br>
    <br>
    For SkyHy by Hubble Monthly, you will receive your next refill order 6 months after your initial order was placed.
    `,
  },
  {
    question: `What happens if my prescription changes?`,
    answer: `
    If your prescription changes, just let us know and we will adjust your ongoing subscription orders. You can always provide us with a copy of your updated prescription or reach out to us at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a>.
    <br>
    <br>
    Remember to update your prescription before we send out your next order! Once your package is shipped, you'll need to pay for a replacement box if you later find out you need a new prescription.
    `,
  },
  {
    question: `Can I change my subscription? How?`,
    answer: `Yes! You are in control. We’ve made it super easy to make changes to your subscription, adjust the frequency of shipments, update your shipping address, and more—all from your Member Portal.
    <br>
    <br>
    <span>Or, if you need help, contact our team via phone at <a href="tel:+1-844-334-1640">+1-844-334-1640</a>, <a href="javascript:zE('messenger', 'open');">chat</a> or by email at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a>.</span>`,
  },
  {
    question: `Can I cancel my subscription?`,
    answer: `
    <span>Yes, you can cancel your subscription at any time by calling us at <a href="tel:+1-844-334-1640">+1-844-334-1640</a>. We’re available 7 days a week, from 9am - 9pm Eastern Time.</span>
    `,
  },
]

export const shippingQuestions = [
  {
    question: `Where do you ship?`,
    answer: `We currently ship to the U.S. and its territories, including Puerto Rico, Guam, the U.S. Virgin Islands, and military APO/DPO/FPO addresses. We also ship to Canada.`,
  },
  {
    question: `How long will it take for my order to arrive?`,
    answer: `All Canadian shipments are delivered by Canada Post, which generally takes between 7-10 business days.`,
  },
  {
    question: `What is the cost of shipping?`,
    answer: `We charge $3.99 CAD for shipping and handling (plus any applicable sales tax) on each contact lens delivery.`,
  },
  {
    question: `Can I change the shipping method once my order has shipped?`,
    answer: `No. You cannot modify the shipping method. Please note: we do not offer expedited shipping options.`,
  },
]

export const paymentQuestions = [
  {
    question: `What forms of payment do you accept?`,
    answer: `We accept all major credit and debit cards.`,
  },
  {
    question: `Do you accept vision care insurance as payment?`,
    answer: `Unfortunately, we don't work with insurance providers directly, but you can submit your invoices to apply for reimbursement from your insurance provider.`,
  },
  {
    question: `How do I get a receipt to submit to my vision care insurance provider?`,
    answer: `You can <a href="/login">download it from your Member Portal</a>, contact us <a href="javascript:zE('messenger', 'open');">via chat</a> or email us at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a> and we'll send you an invoice for your insurance provider.`,
  },
]

export const hubbleContactLensesQuestions = [
  {
    question: `Where are your lenses made?`,
    answer: `Our Hubble Classic Daily lenses are made by our partner, St. Shine, in Taiwan. Hydro by Hubble Daily lenses are manufactured in Singapore by our partner company, Menicon. SkyHy by Hubble Daily and SkyHy by Hubble Monthly lenses are produced by Visco in Malaysia.`,
  },
  {
    question: `What are your lenses made of?`,
    answer: `Our Hubble Classic Daily lenses are made of methafilcon A hydrogel material, which possesses a 55% water content. They offer UV protection and feature a thin edge for comfort.
    <br/>
    <br/>
    <span>Hydro by Hubble Daily lenses are made of hioxifilcon A material with an innovative hygienic touch design. These lenses have smooth edges and a 57% H2O content for the most hydrating and comfortable fit.</span>
    <br/>
    <br/>
    <span>SkyHy by Hubble Daily lenses are made of olifilcon B material which provides high oxygen permeability allowing for highly breathable lenses. This silicone hydrogel lens has a soft flexible fit to ensure comfortable wear.</span>
    <br/>
    <br/>
    <span>SkyHy by Hubble Monthly lenses, like our SkyHy by Hubble Daily lenses, are made of a premium silicone hydrogel material. They’re made of olifilcon A and are designed for maximum breathability.</span>`,
  },
  {
    question: `Are Hubble contact lenses FDA approved?`,
    answer: `Yes, our lenses all have long standing FDA approval and we’ve sold over 600,000,000 prescription lenses!`,
  },
  {
    question: `What power ranges do you offer?`,
    answer: `We currently offer lenses with powers ranging from -0.50 to -12.00 and +0.5 to +6.00. Please refer to specific product information to determine available powers for each.`,
  },
  {
    question: `Do you sell lenses for people with astigmatism (toric lenses)?`,
    answer: `Hubble does not sell toric contact lenses for astigmatism in Canada at this time.`,
  },
  {
    question: `Do you sell weekly or monthly lenses?`,
    answer: `
    Yes! SkyHy by Hubble Monthly lenses are our first contact lenses designed for monthly use. They’re made with breathable silicone hydrogel material, and have all the benefits of the latest in lens technology with a cost efficient subscription.
    <br>
    <br>
    We do not offer contact lenses for weekly wear in Canada at this time.
    `,
  },
  {
    question: `Do you carry colored contact lenses?`,
    answer: `Unfortunately, we do not carry colored contact lenses.`,
  },
  {
    question: `Can teenagers wear Hubble lenses?`,
    answer: `Our lenses are approved for use for anyone age 18+, who has a valid prescription.`,
  },
  {
    question: `Can I use water or contact lens solution to clean or store my Hubble contact lenses?`,
    answer: `
      Hubble Classic Daily Contact Lenses, Hydro by Hubble Daily Contact Lenses, and SkyHy by Hubble Daily Contact Lenses  are all daily disposable lenses, designed for individual use. Please do not attempt to wear them for more than one day.
      <br/>
      <br/>
      SkyHy by Hubble Monthly Contact Lenses can/should be cleaned and stored with fresh lens solution. See the instructions included with your lenses for more specifics. Do not use tap water, bottled water or saliva with the lenses or lens case.
    `,
  },
  {
    question: `What additional information do you have for Hubble contact lens wearers?`,
    answer: `For more information, see the Classic by <a href="https://hubbleassets.s3.amazonaws.com/Insert-for+daily_55+UV_20210511(HUBBLE)+(1)+(4).pdf" target="_blank">Hubble Wearers Guide</a>, the <a href="https://hubbleassets.s3.amazonaws.com/Hubble+IFU_pdf+proof+(2).pdf" target="_blank">Hydro by Hubble Wearers Guide</a>, the <a href="https://www.dropbox.com/scl/fi/7vczra70orxwzd0dwaqt9/SkyHy-Daily-Wearers-Guide.pdf?rlkey=h68ejxo7etnk3tidsgz2nlfwr&st=qs4kpels&raw=1" target="_blank">SkyHy by Hubble Wearers Guide</a>, and the <a href="https://www.dropbox.com/scl/fi/dfmerdzlapk6v51hcwp4i/SkyHy-Monthly-Wearers-Guide.pdf?rlkey=o1i685zfunu3sba8h60qz0x4o&st=rreta8om&raw=1" target="_blank">SkyHy by Hubble Monthly Wearers Guide</a>.`,
  },
]
