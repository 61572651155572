import React, { useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import StaticPageHeader from "../components/StaticPageHeader"
import CanadaFaqSection from "../components/CanadaFaqSection";
import {hubbleGeneralQuestions, shippingQuestions, paymentQuestions, subscriptionQuestions, hubbleContactLensesQuestions} from "../components/CanadaFaqSection/faqs";

const tagOrNewlineRegExp = /<[^>]*>|\n/g;

const questionsSchema = [
  ...hubbleGeneralQuestions,
  ...shippingQuestions,
  ...paymentQuestions,
  ...subscriptionQuestions,
  ...hubbleContactLensesQuestions,
].map(e => e.question && e.answer ? {
    "@type": "Question",
    "name": e.question.replaceAll(tagOrNewlineRegExp, ""),
    "acceptedAnswer": {
      "@type": "Answer",
      "text": e.answer.replaceAll(tagOrNewlineRegExp, ""),
    }
  } : undefined
).filter(Boolean)

const FaqCanada = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/faq/",
      "@type": "WebPage",
      "name": "Hubble Contact Lenses & Eye Glasses Frequently Asked Questions",
      "description": "Discover answers to your frequently asked questions about Hubble prescription daily contacts, frames and glasses, and prescription sunglasses. Find information on orders, monthly and weekly prescription contact subscriptions, prescriptions, online vision tests, and more.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        ...questionsSchema,
      ]
    }
  ]



  return (
    <Layout isCanadaHeader usLink="/faq">
      <SEO
        title="Hubble Contact Lenses & Eye Glasses Frequently Asked Questions"
        description="Discover answers to your frequently asked questions about Hubble contacts, glasses, and sunglasses. Find information on orders, subscriptions, prescriptions, and more."
        schemaMarkup={schemaMarkup}/>
      <StaticPageHeader title="FAQs - Canada" subheader="Have questions? We're here to help."/>
      <CanadaFaqSection/>
    </Layout>
  )
}

export default FaqCanada
